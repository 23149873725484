/**
 * @file Axios 全局配置
 */
import axios from "axios";
import * as auth from "../auth";
import ENV from 'scripts/constants/env'
import { handleCode } from './errorHandler'
import { IsEqual } from 'scripts/util'
import { createMessage } from 'scripts/message'
const Message = createMessage()

const myAxios = axios.create({
  baseURL: ENV.SERVER_ORIGIN,
  withCredentials: true,
  timeout: 30000,
});

/**
 * 取消重复请求
 * @param {String} type request | response
 * @param {Object} config
 * @returns
 */
const requestStore = new Map();
function cancelDuplicateRequests(type, config) {
  let key = {
    method: config.method,
    url: config.url,
    params: config.params
  };

  const keys = requestStore.keys()
  for (let k of keys) {
    if (IsEqual(k, key)) {
      key = k
      break;
    }
  }

  if (type == "request") {
    requestStore.get(key)?.("取消重复请求");
    config.cancelToken = new axios.CancelToken(function executor(cancel) {
      requestStore.set(key, cancel);
    });
    return;
  }

  if (type == "response") {
    requestStore.delete(key);
    return;
  }
}

// 请求拦截
myAxios.interceptors.request.use(
  (config) => {
    cancelDuplicateRequests("request", config);
    config.headers["Auth-Session"] = auth.getToken();
    return config;
  },
  (error) => {
    var err = {
      message: "Network exception, please refresh and try again",
      err: error,
      type: "requestError",
    };
    console.log(err);
    return Promise.reject(err);
  }
);

// 响应拦截
myAxios.interceptors.response.use(
  (res) => {
    cancelDuplicateRequests("response", res.config);
    if (res.status >= 200 && res.status < 300) {
      return handleCode(res);
    }
    return Promise.reject(res);
  },
  (error) => {
    var err = null;
    if (axios.isCancel(error)) {
      err = {
        message: error.message || "Duplicate request cancelled",
        err: error,
        type: "cancel",
      };
    } else {
      // 网络异常
      err = {
        message: "Network exception, please refresh and try again",
        err: error,
        type: "responseError",
      };
      Message.error(err.message)
    }
    console.log(err);
    return Promise.reject(err);
  }
);

export default myAxios;
