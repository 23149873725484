import { GetParameterByName } from 'scripts/util'

const link = {
  pc: 'https://vda-global.lilisi.com/tracking',
  m: 'https://app.adjust.com/gept02v_2q5b5bu?campaign=ROK2023CivilizationClash'
}
const download = {
  direct: {
    dap_code: 'c21579a0d887c4be3ffce12cd781b924',
  },
  official: {
    dap_code: 'c88be9a1a9c61e93d12352d0160a5145',
  },
  mail: {
    dap_code: '6c4cda6189ee48ef1420e710f55d7bca',
  },
  TheGameTheorists: {
    dap_code: '760e003df29976c3532162eff3f47476'
  },
  FaZeRug: {
    dap_code: 'f1c3d2bbdae9edfbf649ff90c204abfb'
  },
  DemolitionRanch: {
    dap_code: '8b4246e70026e1d40b83975234323306'
  },
  Caylus: {
    dap_code: 'd5f778392318b1b2770ae76c1701f20e'
  },
  Ludwig: {
    dap_code: '2672706ef2dc32d43c458a1d2f7c8fa9'
  },
  JimmyHere: {
    dap_code: '2cdf3f2848b06e727dc7721bb3f91e49'
  },
  Schlagg: {
    dap_code: '3d06a35d4c8bd5b398da0a54c09066a3'
  },
  Unspeakable: {
    dap_code: '35be7c163b261e6ff410cd678d48e620'
  },
  Willjum: {
    dap_code: '2c9f95a84dfbf5b4e0b87bfc8d5d15d5'
  },
  TetraBitGaming: {
    dap_code: 'f5ea7e8aa05abcd44ab16629f566493e'
  },
  PressStartToLaugh: {
    dap_code: '86123606bf0710d94dc87c593fdbb671'
  },
  SebbyK: {
    dap_code: 'd8f30690f7224dfd1ff38aff00cd508b'
  },
  Enardo: {
    dap_code: '44b31c176904ba598c7051c14cac7932'
  },
  CaptainEggcelent: {
    dap_code: 'abddb178c645318b0f3f232a1ca4c1b6'
  },
  WillE: {
    dap_code: '8bd21730716545976c3d25a82d61c787'
  },
  Brit: {
    dap_code: 'e96515833404ebde3516d24ae3ca5a75'
  },
  Pilps: {
    dap_code: 'f7d9ba47203f4c00800889330c2fe9bd'
  },
  hJune: {
    dap_code: '9e81f1c7619616442582fd32dd05295a'
  },
  ForceGaming: {
    dap_code: '0612eb1e9992b6fee27f5896f2d94e21'
  },
  Thedemodcracy: {
    dap_code: '1e6e85952a30f092a0cd8f3ee7cfeb7f'
  },
  Joov: {
    dap_code: '5dc0b6ff0157c37f40a68bb04e6c9b59'
  },
  WhosSuda: {
    dap_code: 'f2830fbe324d3a432504b9f4a1f8e7bc'
  },
  Koifish: {
    dap_code: 'af3d730352935c233a360dea7031ffdd'
  },
  TLP: {
    dap_code: '0882b786d1e820463351cc3399e09e31'
  },
  Drumsy: {
    dap_code: 'ce5e6f12af535ff384aa6e11ee4ad41b'
  },
  LuckyGhost: {
    dap_code: 'fa91e75a9f0111df58286e1e76293b78'
  },
  NoglaTerroriserREACT: {
    dap_code: '890a493e7ca13c0b74a98ebe463b4b1e'
  },
  TheEpicNate315: {
    dap_code: 'fff4eb13725401958f35360b2c25171e'
  },
  ADayInHistory: {
    dap_code: 'b69f123f40bdb30dc4ddaa07703b7441'
  },
  Vadact: {
    dap_code: '09f2ea03ae9aa97c1b0f16af3b759419'
  },
  JuiceHead: {
    dap_code: '840a4406eef060f9614960f71668c695'
  },
  Dirtrider479: {
    dap_code: '726865e819d7083361ad065c78d08b98'
  },
  ItsHistory: {
    dap_code: 'a3d1a593cb763934613c7849daaf83a3'
  },
  Squire: {
    dap_code: '47be7195872273069a86bd42ac30c724'
  },
  GVMERS: {
    dap_code: 'ed491d66e136b82808b45e4651905cd0'
  },
  RoboKast: {
    dap_code: '0bc2f8e4be217084b9d4a7e758e36a9d'
  },
  Tewtiy: {
    dap_code: 'e9b44ef2ce453674a57e7cb89ecb76e8'
  },
  Konfuzion: {
    dap_code: 'cf9d6dbe35cab573fc2825071546c919'
  },
  Ehroar: {
    dap_code: '354a78d3cc785d268b23c9fd7440688a'
  },
  WILDCAT: {
    dap_code: '78e8c25e5c0feb9731180eb0db875cb4'
  },
  iSorrowproductions: {
    dap_code: '0c2c46e2c987fb8e9acec686c16ad081'
  },
  Blitz: {
    dap_code: 'e3418dd40b78d42c5773d35ae9ecf6f4'
  },
  QuarbitGaming: {
    dap_code: '759f6bff88651f52b02c312a80ee9d88'
  },
  KingsandGenerals: {
    dap_code: '5a97836ee6994f4a8572c569e9b7c44e'
  },
  PapaJake: {
    dap_code: 'a738a43ed902083d4bed770a225d20db'
  },
  Metatron: {
    dap_code: '86436f1749ebb003a9a3a2025d12c127'
  },
  VanossGaming: {
    dap_code: 'd7cc714068e3424603a5c8ae67e78fbf'
  },
  Bokoen1: {
    dap_code: '5bc69576beba3afa98032a70fd6a7abb'
  },
  Bitt3rSteel: {
    dap_code: '2c768f3a04d97cdbe2ab46f0d4e3cef4'
  },
  NaturalCauzes: {
    dap_code: '2ca503a7767bdafdd9b207c06b4ea5b2'
  },
  SimpleHistory: {
    dap_code: '725bd62c056365e9469924b208b8e674'
  },
  ArkSolo: {
    dap_code: '1f5f3c057222aa8a97982f7e1c26feda'
  },
  Crowmeda: {
    dap_code: '5dc3f94e3ededc51d25bf1b155648e76'
  },
  SignsOfKelani: {
    dap_code: 'fdca4c38eea72ac99312b2655d72fe4d'
  },
  OrangeJuiceGaming: {
    dap_code: '18ac143f655998f8b4d59008a403d933'
  },
  'SirTagCR-ClashRoyale': {
    dap_code: 'b36cbc21c3e96ca17a0ac3189af71cef'
  },
  Camomo_10: {
    dap_code: '7f2b9aef5498184f9fa4602252e532b9'
  },
  Specialedd: {
    dap_code: 'a089f31d74568b24a7ddfd1ca6e5b5ef'
  },
  Littlez: {
    dap_code: 'ecc84c35df74bc1a28e37f529197e589'
  },
  TheBoys: {
    dap_code: '502743774339f76dec16fa5262353281'
  },
  Narrator: {
    dap_code: 'b85e1277bbde2fcc389b95e43cb7d169'
  },
  Alpharad: {
    dap_code: '74e7222da8ebcde01738d885c38db96d'
  },
  JudoSloth: {
    dap_code: 'c0d65ad24bf995e77ec43eaf471fafd6'
  },
  TheGrimKleaper: {
    dap_code: '7801bf674365be0dbc8dcf7aedbe25c0'
  },
  AdemBilal: {
    dap_code: '623b79de446258a7039bda2a4fa80d7a'
  },
  ALEKS: {
    dap_code: '950dbb8d15f8ec8d80fc51afd131a81a'
  },
  WankilStudio: {
    dap_code: '001818d3224039adee501b1ac6e1a42b'
  },
  DIABOLO: {
    dap_code: 'fe82f3373d50e273b797a41cd9afc22d'
  },
  benzaieTV: {
    dap_code: 'c7efa629ac9bf55e75e2473e4b6f39a4'
  },
  Amixem: {
    dap_code: 'a2806748777aa8fda009c31a22bb2003'
  },
  RAYTON: {
    dap_code: 'fe2a54c60c50c40420ca8d964b73da0a'
  },
  Trash: {
    dap_code: '692fc843b3f09642d7d9079022476e56'
  },
  HenryLive: {
    dap_code: 'e72889d3e2f971706af6bae9d2394173'
  },
  LaMarmotaEspacial: {
    dap_code: 'fdd780a714f3c9fb5f998adcf7fe8e90'
  },
  VistaGamer: {
    dap_code: 'fcb7b4d0ef2774715542019a18ebc02d'
  },
  YOLO: {
    dap_code: 'b03b702e4e4be06064aa0ec39c09009e'
  },
  Chiches: {
    dap_code: '51fec133e14aec53c596c18c6e1218a6'
  },
  MarioOvalle: {
    dap_code: 'b038f3a21daaa3a13634eea80be5c3dc'
  },
  NachoAoE: {
    dap_code: '5abfdcc968d49b527f996fb129db4d0e'
  },
  hugothester: {
    dap_code: 'ef9c1488867e0db9ff6f60a4c5b4435d'
  },
  ElEscocesgamer: {
    dap_code: '6b5dec970d5a8c630a5ca792e1788247'
  },
  Arqueira: {
    dap_code: '10f852b10ddd4a1a4050656b5856d331'
  },
  Balian: {
    dap_code: '059bbd2c20ba3a8ea87d5c6294ad8c6c'
  },
  GEMAPLYS: {
    dap_code: '94da61a0b1d4e666efc2ede286032323'
  },
  GigatonGames: {
    dap_code: '571939e6f4c3b9560e79bb349c489e17'
  },
  Flanela: {
    dap_code: '9b26142760a4051fa37b1fef30433040'
  },
  NahimSky: {
    dap_code: 'f0f47f0426f928cf1939ff4fa1cd23b5'
  },
  Mustihaft: {
    dap_code: 'eef766bf3237b66eaa7c8071fe3c58f9'
  },
  Maki: {
    dap_code: '853c880f1c91cf79c5e8524d8d4339e8'
  },
  unsympathischtv: {
    dap_code: '5d0e7cc613aa3335e2aca2f97d26e4bc'
  },
  Paulberger: {
    dap_code: '45a6a4c7ad94506ba1c5328086eb2abe'
  },
  PJAdventure: {
    dap_code: '9836170578fa0b1c9a8fd4a4da5a708c'
  },
  GameStar: {
    dap_code: '6b54fa7eb4514eed52f012e097403a99'
  },
  rewinside: {
    dap_code: '65656faba84415595896af87b63d9b9b'
  },
  Cyrad: {
    dap_code: 'e0f60078db56a6208d028c16bfa10bfa'
  },
  JohnnyHand: {
    dap_code: 'd9c4279ce766f8a3bd37c6e0478c7f29'
  },
  LetsHugo: {
    dap_code: 'af059352076280ab2bbf679ceb60b0f9'
  },
}

let kol = GetParameterByName('kol', window.location.href)
if (kol && download[kol]) {
  window.gtag('event', kol)
} else {
  kol = 'direct'
}
const query = download[kol] || download.direct
const qKeys = Object.keys(query)
const downloadLink = {
  pc: link.pc + '?' + qKeys.reduce((p, c, i) => {
    return p + `${c}=${query[c]}${i < qKeys.length - 1 ? '&' : ''}`
  }, ''),
  m: link.m + '&creative=' + kol
}

export default downloadLink