/**
 * @file 常量 - 环境
 */
let env = '',
  origin = '', // 网站域名
  serverOrigin = '' // 服务器域名

if (/www\.rok\.games/.test(location.hostname)) {
  // 正式环境
  env = 'prod'
  origin = location.origin
  serverOrigin = "https://rok-be.rok.games"
} else if (/^tdstest\.svc\.luckyxp\.com\.cn/.test(location.hostname)) {
  env = 'test'
  origin = location.origin + '/rokactivity'
  serverOrigin = 'https://tdstest.svc.luckyxp.com.cn/rokactivityapi'
} else {
  env = 'dev'
  origin = location.origin
  serverOrigin = 'http://127.0.0.1:8000'
}

export default {
  ENV: env,
  ORIGIN: origin,
  SERVER_ORIGIN: serverOrigin
}