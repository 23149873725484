<template>
  <div>
    <Dialog
      v-bind="$attrs"
      v-on="$listeners"
      :title="locale.loginNow"
      :visible="show"
      @close="onClose"
    >
      <form @submit.prevent class="form ff--common" autocomplete="off">
        <div class="form-item uid">
          <label for="uid">{{ locale.gameUID }}:</label>
          <input
            :placeholder="locale.enterGameUid"
            id="uid"
            name="uid"
            type="text"
            v-model="formData.uid"
          />
        </div>
        <div class="form-item code">
          <label for="code">{{ locale.verificationCode }}:</label>
          <input
            :placeholder="locale.enterCode"
            id="code"
            name="code"
            type="text"
            v-model.trim="formData.code"
          />
          <span
            v-gtag:getCode
            data-type="button"
            id="getcode"
            class="getcode"
            @click="getCode"
            ><i v-if="loading.code" class="loading el-icon-refresh-right"></i>
            {{ countdown ? countdown + "s" : locale.getCode }}</span
          >
        </div>
        <div class="form-item help">
          <span
            v-gtag:whereToGetUID
            id="login-help"
            data-type="link"
            @click="help('open')"
            >{{ locale.howToGet }}</span
          >
        </div>
        <div
          class="form-item agree"
          :class="{ 'agree--checked': formData.agree }"
        >
          <input
            id="agree"
            name="agree"
            type="checkbox"
            v-model="formData.agree"
          />
          <label v-gtag:agree for="agree" ref="agree"> </label>
        </div>
        <button
          v-gtag:login
          data-type="button"
          class="dialog-btn submit"
          @click="submit"
        >
          <span
            ><i v-if="loading.submit" class="loading el-icon-refresh-right"></i>
            {{ locale.loginNow }}</span
          >
        </button>
      </form>
    </Dialog>

    <HowGetUid :visible="showHowGetUid" @close="help('close')" />
  </div>
</template>

<script>
import Dialog from "./dlg.vue";
import HowGetUid from "./how-get-uid.vue";
import { mapActions } from "vuex";
import cfgData from "scripts/constants/configData";

let timer = null;
export default {
  name: "LoginDialog",
  props: ["visible"],
  components: {
    Dialog,
    HowGetUid,
  },
  data() {
    const locale = this.$locale.dialog.login;

    return {
      locale,
      show: false,
      showHowGetUid: false,
      countdown: 0,
      formData: {
        uid: "",
        code: "",
        agree: false,
      },
      rules: {
        uid: [
          {
            message: locale.enterUID,
            validator(val) {
              return !!val;
            },
          },
          {
            message: locale.enterValidUID,
            validator(val) {
              return !!/^\d+$/.test(val);
            },
          },
        ],
        code: [
          {
            message: locale.enterCode,
            validator(val) {
              return !!val;
            },
          },
        ],
        agree: [
          {
            message: locale.readPolicy,
            validator(val) {
              return !!val;
            },
          },
        ],
      },
      loading: {
        code: false,
        submit: false,
      },
    };
  },
  created() {},
  mounted() {},
  watch: {
    visible: {
      handler(val) {
        this.show = val;
        if (val) {
          this.$nextTick(() => {
            let idx = 0;
            const res = this.locale.agree?.replaceAll(
              /\$\{([\w\s]+)\}/g,
              function () {
                return `<a data-type="link" target="_blank" href="${
                  cfgData.policy[idx++]
                }">${arguments[1]}</a>`;
              }
            );
            this.$refs.agree.innerHTML = res;
          });
        }
      },
      immediate: true,
    },
  },
  computed: {},
  filters: {},
  methods: {
    ...mapActions(["login"]),
    // 关闭
    onClose() {
      clearInterval(timer);
      this.show = false;
      this.formData = {
        uid: "",
        code: "",
        agree: false,
      };
      this.countdown = 0;
      this.$emit("close");
    },
    // 提交表单
    submit() {
      if (this.loading.submit) {
        return;
      }
      if (!this.validateFields()) {
        return;
      }
      this.loading.submit = true;
      this.login({
        code: this.formData.code,
        uuid: +this.formData.uid,
      })
        .then(() => {
          this.$tracker.click_login("success");
          this.$message({
            type: "success",
            message: this.$locale.toast.loginSuccess,
          });
          this.$emit("success");
          this.onClose();
        })
        .finally(() => {
          this.loading.submit = false;
        });
    },
    // 校验表单
    validateFields(name) {
      if (!name) {
        return Object.keys(this.rules).every((k) => this.validateFields(k));
      }
      for (let rule of this.rules[name]) {
        if (!rule.validator(this.formData[name])) {
          console.log(rule.message);
          this.$message({
            type: "warning",
            message: rule.message,
          });
          return false;
        }
      }
      return true;
    },
    // 获取验证码
    async getCode() {
      if (this.loading.code) {
        return;
      }
      if (timer || this.countdown > 0) {
        return;
      }
      if (!this.validateFields("uid")) {
        return;
      }
      clearInterval(timer);
      this.loading.code = true;
      try {
        await this.$req.getCode({
          uuid: this.formData.uid,
          lang: "ENG",
        });
        this.$message(this.$locale.dialog.login.codeSent);
        this.countdown = 60;
        timer = setInterval(() => {
          this.countdown -= 1;
          if (this.countdown <= 0) {
            clearInterval(timer);
            timer = null;
          }
        }, 1000);
      } catch (err) {
        clearInterval(timer);
        this.countdown = 0;
        timer = null;
        console.error(err);
      } finally {
        this.loading.code = false;
      }
    },
    //
    help(action) {
      if (action == "open") {
        this.showHowGetUid = true;
      } else if (action == "close") {
        this.showHowGetUid = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .dialog-body {
  padding: 40px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-item {
  @input-bgcolor: #4d2e1f;
  @input-color: #fff;

  display: flex;
  align-items: center;

  label {
    display: inline-block;
    padding-right: 10px;
    width: 228px;
    font-size: 24px;
    color: var(--primary-color);
    text-align: right;
    text-shadow: 1px 2px 0px rgba(57, 46, 32, 0.54),
      0px 0px 1px rgba(255, 233, 129, 0.69);
  }

  input {
    flex: 1;
    padding: 0 16px;
    width: 398px;
    height: 50px;
    background-color: @input-bgcolor;
    border-radius: 7px;
    border: solid 1px #c39f6b;
    font-size: 24px;
    color: @input-color;
  }

  // 更改表单自动填充时的 字体颜色（无法修改） 和 背景填充色
  // input:-webkit-autofill {
  //   /* 设置文本颜色 */
  //   color: @input-color !important;
  //   -webkit-box-shadow: 0 0 0 30px @input-bgcolor inset !important;
  // }

  &.uid {
    margin-bottom: 44px;
  }

  &.code {
    input {
      width: 184px;
    }

    .getcode {
      flex: 1 0 auto;
      padding: 0 10px;
      margin-left: 10px;
      width: 204px;
      height: 50px;
      background-color: #eca426;
      box-shadow: 0px 0px 2px 1px rgba(57, 46, 32, 0.64),
        inset 1px 3px 0px 0px rgba(255, 250, 175, 0.17);
      border-radius: 7px;
      font-size: 18px;
      line-height: 50px;
      color: #fff;
      text-align: center;
      text-shadow: 1px 1px 0px rgba(131, 113, 83, 0.96);
    }
  }

  &.help {
    align-self: flex-end;
    margin-top: 6px;
    padding-right: 0;
    justify-content: flex-end;
    font-size: 12px;
    line-height: 20px;
    color: #e2cea4;
  }

  &.agree {
    position: relative;
    justify-content: center;
    margin-top: 30px;
    cursor: pointer;

    ::v-deep a {
      color: var(--primary-color);
      text-decoration: none;
    }

    input {
      display: none;
      flex: 0 0 auto;
      opacity: 0;
      margin-right: 10px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    label {
      &::before {
        content: "";
        position: relative;
        top: -1px;
        display: inline-block;
        width: 14px;
        height: 14px;
        padding: 2px;
        margin-right: 5px;
        border: 1px solid #fff;
        border-radius: 50%;
        pointer-events: none;
        cursor: pointer;
        vertical-align: middle;
        text-align: center;
        background-color: transparent;
        background-clip: content-box;
      }
    }

    label,
    a {
      width: auto;
      font-size: 14px;
      line-height: 20px;
      color: #fcf7e4;
      text-shadow: none;
      cursor: pointer;
    }
  }

  &.agree--checked {
    label::before {
      background-color: rgb(248, 166, 14);
    }
  }
}

.submit {
  margin: 0 auto;
  width: 380px;
  margin-top: 20px;
}
</style>
