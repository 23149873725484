<template>
  <Dialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="locale.howToGet"
    :hasDecor="false"
  >
    <div class="step step1">
      <div class="step-title dialog-title has-decor">{{ locale.gameUID }}</div>
      <div class="step-detail ff--common">{{ locale.goToProfileCopyUID }}</div>
      <div class="step-img ff--common">
        <span>click to see your profile</span>
        <span>your ID here</span>
      </div>
    </div>
    <div class="step step2">
      <div class="step-title dialog-title has-decor">
        {{ locale.verificationCode }}
      </div>
      <div class="step-detail ff--common">{{ locale.afterSelecting }}</div>
      <div class="step-img ff--common">
        <span>under system</span>
        <span>go to mail</span>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "./dlg.vue";

export default {
  name: "HowGetUidDialog",
  components: {
    Dialog,
  },
  data() {
    return {
      locale: this.$locale.dialog.howToGetUIDAndCode,
    };
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="less" scoped>
::v-deep .dialog {
  &-content {
    height: 928px;
  }

  &-title {
    font-size: 28px;
  }

  &-body {
    padding: 40px 0;
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  // width: 565px;
  // margin: 0 auto;

  &-title {
    display: inline-block;
    margin-bottom: 15px;
  }

  &-detail {
    margin: 0 auto 5px;
    font-size: 15px;
    line-height: 30px;
    color: #fcf7e4;
    // white-space: nowrap;
  }

  &-img {
    width: 565px;
    height: 288px;
    background: url(~images/dialog/help_1.png) repeat-x 0 0;
    background-size: 100% 100%;
    color: #fff;
  }

  &.step1 {
    margin-bottom: 20px;

    .step-img {
      position: relative;

      span:nth-child(1) {
        position: absolute;
        top: 20px;
        left: 84px;
      }

      span:nth-child(2) {
        position: absolute;
        top: 74px;
        left: 248px;
      }
    }
  }

  &.step2 {
    .step-img {
      position: relative;
      height: 243px;
      background-image: url(~images/dialog/help_2.png);

      span:nth-child(1) {
        position: absolute;
        top: 94px;
        left: 240px;
      }

      span:nth-child(2) {
        position: absolute;
        top: 75px;
        right: 10px;
      }
    }
  }
}
</style>
