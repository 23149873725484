/**
 * @file vue 全局过滤器
 */
import Vue from 'vue'

/**
 * 时间格式化
 * @param {Number|String} time - 时间戳或字符串
 * @param {String} format - 时间格式：'YYYY-MM-DD hh:mm:ss'（默认值）
 * @param {Boolean} simplify - 是否简化时间格式
 */
Vue.filter('adFormatTime', function (time, format = 'YYYY-MM-DD hh:mm:ss', simplify) {
  if (typeof time == 'string' && time.indexOf('T') === -1) {  //排除格林威治时间
    time = time.replace(/-/g, '/')
  }
  if (String(time).length < 11) {
    time += '000';
  }
  let date = new Date(Number(time));

  // #region 简化时间格式，最多 7 天
  if (simplify) {
    let result = ''
    let difference = Date.now() - date.getTime()
    if (difference < 60000) {
      result = '刚刚'
    } else if (difference < 3600000) {
      result = parseInt(difference / 60000) + ' 分钟前'
    } else if (difference < 86400000) {
      result = parseInt(difference / 3600000) + ' 小时前'
    } else if (difference < 604800000) {
      result = parseInt(difference / 86400000) + ' 天前'
    }
    if (result) {
      return result
    }
  }
  // #endregion

  const patterns = {
    'M+': date.getMonth() + 1,
    'D+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  if (/Y+/.test(format)) {
    format = format.replace(RegExp.lastMatch, (date.getFullYear() + '').slice(4 - RegExp.lastMatch.length))
  }
  for (let p in patterns) {
    if (new RegExp('(' + p + ')').test(format)) {
      format = format.replace(RegExp.lastMatch, RegExp.lastMatch.length == 1 ? patterns[p] : String(patterns[p]).padStart(2, '0'))
    }
  }
  return format
})

/**
 * 数字格式化
 * @param {Number} num - 待格式化的数字
 */
Vue.filter('adFormatNumber', function (num) {
  if (typeof num == 'undefined') {
    return ''
  }
  let result = num.toString()
  if (num > 100000000) {
    result = (num / 100000000).toFixed(1) + '亿'
  } else if (num > 10000) {
    result = (num / 10000).toFixed(1) + '万'
  }
  return result
})

/**
 * 获取url参数
 * @param {*} name 参数名
 * @param {*} url
 * @returns
 */
Vue.filter('getParameterByName', function (name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[]]/g, '$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\\+/g, ' '));
})

/**
 * 空值展示
 * @param {*} val 
 * @param {*} hasEmptyString  是否包含空字符串
 * @returns
 */
function emptyValueDisplay(val, includeEmptyString = true) {
  if (val === undefined || val === null || (includeEmptyString && val === '')) return '-'

  return val
}
Vue.filter('emptyValueDisplay', emptyValueDisplay)

/**
 * 空值展示
 * @param {*} val 
 * @param {*} num 隐藏几位，默认 4
 * @param {*} placeholder 占位符，默认 *
 * @returns
 */
Vue.filter('privacy', function (val, num = 4, placeholder = "*") {
  if (!val) return emptyValueDisplay(val)
  if (typeof val !== 'string') return val
  const len = val.length
  if (len < 3) return val
  if (len < num + 2) {
    return val.slice(0, 1) + Array(len - 2).fill(placeholder).join('') + val.slice(len - 1)
  }
  const replacedText = Array(num).fill(placeholder).join('')
  const frontLen = Math.floor((len - num) / 2)
  return val.slice(0, frontLen) + replacedText + val.slice(frontLen + num)
})