var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    _vm._g(
      _vm._b(
        { attrs: { title: _vm.locale.howToGet, hasDecor: false } },
        "Dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "step step1" }, [
        _c("div", { staticClass: "step-title dialog-title has-decor" }, [
          _vm._v(_vm._s(_vm.locale.gameUID)),
        ]),
        _c("div", { staticClass: "step-detail ff--common" }, [
          _vm._v(_vm._s(_vm.locale.goToProfileCopyUID)),
        ]),
        _c("div", { staticClass: "step-img ff--common" }, [
          _c("span", [_vm._v("click to see your profile")]),
          _c("span", [_vm._v("your ID here")]),
        ]),
      ]),
      _c("div", { staticClass: "step step2" }, [
        _c("div", { staticClass: "step-title dialog-title has-decor" }, [
          _vm._v("\n      " + _vm._s(_vm.locale.verificationCode) + "\n    "),
        ]),
        _c("div", { staticClass: "step-detail ff--common" }, [
          _vm._v(_vm._s(_vm.locale.afterSelecting)),
        ]),
        _c("div", { staticClass: "step-img ff--common" }, [
          _c("span", [_vm._v("under system")]),
          _c("span", [_vm._v("go to mail")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }