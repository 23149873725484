/**
 * @file 项目入口文件
 */
import { ToMobile } from 'scripts/util'
ToMobile()

import Vue from 'vue'

import { ENV } from 'scripts/constants'
if (ENV.ENV == 'prod') {
  console.log = () => { }
}

import * as Request from 'scripts/requests'
Vue.prototype.$req = Request.reqPublic

import Tracker from './tracker'
Vue.prototype.$tracker = Tracker

import { Dialog, MessageBox, Collapse, CollapseItem, Loading } from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import Message from './message'
Vue.use(Dialog);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Loading);
Vue.use(Loading.directive);
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message

import { getLocale } from './locales'
getLocale()

import * as Auth from './auth'
Vue.prototype.$auth = Auth

import Share from './share'
Vue.prototype.$share = Share

import BusLogin from '@/bus/login'
Vue.prototype.$login = BusLogin

import Store from './store'
Vue.prototype.$store = Store

import { DOWNLOAD_LINK } from 'scripts/constants'
Vue.prototype.$download = DOWNLOAD_LINK

import App from 'modules/app/index.vue'
import './directives'
import './filters'
import 'styles/commonStyle.css'
import 'styles/element-ui.css'

new Vue({
  el: '#app',
  render: h => h(App)
})
