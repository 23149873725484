export default {
  // TODO kol数据，待提供
  kolList: [
    {
      name: 'The Game Theorists',
      civi: '希腊',
      tag: require('images/civi/greece/tag.png'),
      avatar: require('images/kol/avatar/TheGameTheroists.jpg'),
      intro: `Greece: Cool gods, beautiful beaches, and home to the smartest minds of ancient history. Greece is peak civilization. That's not theory...that's a FACT!`
    },
    {
      name: 'FaZe Rug',
      civi: '埃及',
      tag: require('images/civi/egypt/tag.png'),
      avatar: require('images/kol/avatar/FazeRug.jpg'),
      intro: `Egypt is hands down the best civilization. I mean who could say no to Cleopatra?`
    },
    {
      name: 'DemolitionRanch',
      civi: '维京',
      tag: require('images/civi/vikings/tag.png'),
      avatar: require('images/kol/avatar/DemolitionRanch.jpg'),
      intro: `I've been thinking about adding a few people to the Demolitia. What do we think about Vikings? They seem pretty tough. I mean obviously not as tough as me, but it wouldn't be fair to hold them to that standard.`
    },
    {
      name: 'Caylus',
      civi: '西班牙',
      tag: require('images/civi/spain/tag.png'),
      avatar: require('images/kol/avatar/Caylus.png'),
      intro: `Spain is going to win this war! I mean, who would dare to go against the mighty matadors?`
    },
    {
      name: 'Ludwig',
      civi: '德国',
      tag: require('images/civi/germany/tag.png'),
      avatar: require('images/kol/avatar/Ludwig.jpg'),
      intro: `Truly a God Gamer civilization for God Gamers like myself.`
    },
    {
      name: 'JimmyHere',
      civi: '法国',
      tag: require('images/civi/france/tag.png'),
      avatar: require('images/kol/avatar/JimmyHere.jpg'),
      intro: `If you join me and choose France, you will (Bag GET) "baguette" the best bonuses, & conquer your opponents in your journey in Rise of Kingdoms`
    },
    {
      name: 'Schlagg',
      civi: '罗马',
      tag: require('images/civi/rome/tag.png'),
      avatar: require('images/kol/avatar/Schlagg.jpg'),
      intro: `Rome is best because of the pope, obviously.`
    },
    {
      name: 'Unspeakable',
      civi: '英国',
      tag: require('images/civi/britain/tag.png'),
      avatar: require('images/kol/avatar/Unspeakable.jpg'),
      intro: `I choose Britain because they have a great starting commander, their troop training speed buff is great and their Archers get attach bonuses!`
    }
  ],
  civiList: [
    { name: 'arabia', id: 'arabia', tag: '' },
    { name: 'britain', id: 'britain', tag: '' },
    { name: 'byzantium', id: 'byzantium', tag: '' },
    { name: 'china', id: 'china', tag: '' },
    { name: 'egypt', id: 'egypt', tag: '' },
    { name: 'france', id: 'france', tag: '' },
    { name: 'germany', id: 'germany', tag: '' },
    { name: 'greece', id: 'greece', tag: '' },
    { name: 'japan', id: 'japan', tag: '' },
    { name: 'korea', id: 'korea', tag: '' },
    { name: 'ottoman Empire', id: 'ottomanEmpire', tag: '' },
    { name: 'rome', id: 'rome', tag: '' },
    { name: 'spain', id: 'spain', tag: '' },
    { name: 'vikings', id: 'vikings', tag: '' },
  ],
  taskList: [
    { name: 'loginGame', completed: false },
    { name: 'sharePage', completed: false },
    { name: 'chooseCivi', completed: false },
    { name: 'reachLv8', completed: false },
    { name: 'researchPathfinding', completed: false },
    { name: 'reach10000', completed: false },
  ],
  defaultLang: 'en',
  policy: [
    // 服务条款
    'https://www.lilithgames.com/termofservice?locale=en-US',
    // 隐私协议
    'https://www.lilithgames.com/privacy?locale=en-US'
  ],
  links: {
    discord: 'https://discord.gg/riseofkingdoms',
    facebook: 'https://www.facebook.com/riseofkingdomsgame/',
    twitter: 'https://twitter.com/riseofkingdoms',
    youtube: 'https://www.youtube.com/channel/UC7fU_pfnhSBM1RkBz-pJoHA',
    reddit: 'https://www.reddit.com/r/Riseofkingdoms/',
    instagram: 'https://www.instagram.com/riseofkingdoms/',
    tiktok: ''
  },
  code: 'Greece4RoK',
  // 轮播图播放间隔 10s
  swiperPlayDelay: 10000
}