var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "dialog",
        visible: _vm.show,
        width: _vm.width,
        "before-close": _vm.onBeforeClose,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-inner" }, [
        _c(
          "div",
          { staticClass: "dialog-content" },
          [
            _vm._t("header", function () {
              return [
                _c("div", { staticClass: "dialog-header ff--trajanpro-bold" }, [
                  _c("div", {
                    staticClass: "dialog-close",
                    on: { click: _vm.close },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-title",
                      class: _vm.hasDecor ? "has-decor" : "",
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(_vm.title) + "\n          "
                      ),
                    ]
                  ),
                ]),
              ]
            }),
            _c(
              "div",
              { staticClass: "dialog-body ff--trajanpro-bold" },
              [_vm._t("default")],
              2
            ),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }