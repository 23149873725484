/**
 * @file 状态管理
 */
import Vue from 'vue'
import Vuex from 'vuex'
import { reqPublic } from "scripts/requests";
import * as auth from "./auth";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: auth.isLogin(),
    isJoinCivi: null,
    userInfo: {},
  },
  mutations: {
    SET_ISLOGIN: (state, data) => (state.isLogin = data),
    SET_ISJOINCIVI: (state, data) => (state.isJoinCivi = data),
    SET_USERINFO: (state, data) => (state.userInfo = data),
    SET_CIVI_RANKINGS: (state, data) => (state.civiRankings = data),
  },
  actions: {
    async login({ dispatch, commit }, data) {
      try {
        const res = await reqPublic.login(data);
        auth.setToken(res.data.token);
        commit("SET_ISLOGIN", true);
        dispatch('getUserInfo')
      } catch (err) {
        console.error(err);
        return Promise.reject(err)
      }
    },
    async getUserInfo({ commit, dispatch }) {
      try {
        const res = await reqPublic.getUserInfo();
        commit('SET_USERINFO', res.data)
        const civiId = res.data?.user_info?.guild
        commit('SET_ISJOINCIVI', Boolean(civiId))
        console.log('userInfo', res.data);
      } catch (err) {
        console.error(err);
        return Promise.reject(err)
      }
    },
    async joinCivi({ commit, dispatch }, params) {
      try {
        const res = await reqPublic.joinCivi(params);
        auth.setToken(res.data.token);
        dispatch('getUserInfo')
      } catch (err) {
        console.error(err);
        return Promise.reject(err)
      }
    },
    async raffle({ commit, dispatch }) {
      try {
        const res = await reqPublic.raffle();
        dispatch('getUserInfo')
        return res.data
      } catch (err) {
        console.error(err);
        return Promise.reject(err)
      }
    },
    async refreshTaskStatus({ dispatch }) {
      try {
        const res = await reqPublic.refreshTaskStatus();
        dispatch('getUserInfo')
      } catch (err) {
        console.error(err);
        return Promise.reject(err)
      }
    },
    async sharePlatform({ dispatch }) {
      try {
        const res = await reqPublic.share();
        dispatch('getUserInfo')
      } catch (err) {
        console.error(err);
        return Promise.reject(err)
      }
    }
  },
  getters: {
    isLogin: (state) => state.isLogin,
    isJoinCivi: (state) => state.isJoinCivi,
    userInfo: (state) => state.userInfo,
    civiRankings: (state) => state.civiRankings,
  },
});
