import Vue from 'vue'
import Tracker from './tracker'

let fn = function (ev) {
  const el = ev.currentTarget
  const { eventName, params } = el.$__gtag__
  console.log('gtag===========', eventName, params)
  Tracker['click_' + eventName]?.(params)
}
// 埋点
Vue.directive('gtag', {
  bind: function (el, binding) {
    el.$__gtag__ = {
      eventName: binding.arg,
      params: binding.value
    }
    el.addEventListener('click', fn)
  },
  update: function (el, binding) {
    el.$__gtag__ = {
      eventName: binding.arg,
      params: binding.value
    }
  },
  unbind(el) {
    el.removeEventListener('click', fn)
  }
})