import { createMessage } from 'scripts/message'
const Message = createMessage()
import BusLogin from '@/bus/login'

const code = {
  50000: () => {
    BusLogin.$emit('open')
    return 'Not Logged In' // '未登录'
  },
  50001: 'Not joined civilization', // '未报名',
  40000: 'Login failed', // 'token错误',
  40001: 'Login failed', // '登录失败',
  40002: 'Request Error', // '参数错误',
  40003: 'Request Error', // '数据库错误',
  40004: 'Request Error', // 'redis错误',
  40005: 'Request Error', // '其他错误',
  40006: 'Verification code error', // '验证码错误',
  40007: 'Request Error', // '请求错误',
}

function printError(url, method, data) {
  let msg = code[data.code] || code[40007]
  msg = typeof msg == 'function' ? msg() : msg
  if (data.msg || msg) {
    Message.error(data.msg || msg)
  }

  console.log('#################################')
  console.log('请求接口：', url)
  console.log('请求方法：', method)
  console.log('接口返回数据：', JSON.stringify(data))
  console.log('错误码：', data.code)
  console.log('错误信息：', msg)
  console.log('#################################')
}

async function handleCode(resp) {
  const { config: { url, method }, data } = resp
  if (data.code == 0) {
    return Promise.resolve(data)
  } else {
    printError(url, method, data)
    return Promise.reject(data.msg)
  }
}

export {
  handleCode
}