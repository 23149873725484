import Vue from 'vue'
import cfg from '../constants/configData.js'
import en from './langs/en.js'

const langs = {
  en
}

// TODO 获取本地语言类型
/**
 * 获取本地语言类型
 * @returns String
 */
function getLang() {
  const lang = navigator.language?.toLowerCase()
  return lang
}

function getLocale(lang) {
  if (!lang) {
    lang = getLang()
  }
  Vue.prototype.$locale = langs[lang] || langs[cfg.defaultLang]
}

export {
  getLang,
  getLocale
}