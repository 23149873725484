/**
 * @file 工具函数
 * 常用方法，公共方法
 */
import Vue from 'vue'
import ClipboardJS from "clipboard";
import isEqual from 'lodash.isequal'
import ENV from 'scripts/constants/env'
import QRCode from 'qrcode'

/**
 * @name GetLabelFormValue 对象数组中根据一个字段获取对象
 * @param {Array[Object]} array - 对象数组
 * @param {String} key - 字段名
 * @param {any} value - 该字段的预设值
 * @param {String} label - 可选，无匹配对象的空值字段
 */
const GetObjFormKey = function (array, key = 'value', value = '', label) {
  if (!array) return {}

  for (var item of array) {
    if (String(item[key]) === String(value)) {
      return item
    }
  }
  if (label) {
    var result = {}
    result[label] = ''
    return result
  } else {
    return {}
  }
}

/**
 * 模拟a标签跳转
 * @param url
 * @param target
 * @return {*}
 */
const ALink = function (url, target = '_blank') {
  const a = document.createElement('a');
  a.href = url;
  a.target = target;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * 保存图片到本地
 * @param url
 * @param fileName
 * @return {*}
 */
function SavePicture(url, fileName = new Date().getTime()) {
  var blob = new Blob([""], { type: "application/octet-stream" });
  var oUrl = URL.createObjectURL(blob);
  var a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  var e = new MouseEvent(
    "click",
    (true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  );
  a.dispatchEvent(e);
  URL.revokeObjectURL(oUrl);
}

/**
 * 计算军团成员总数
 * @param url
 * @param fileName
 * @return {*}
 */
function CalcGroupNumber(group = {}) {
  if (!group?.creator?.name) return 0
  let num = 0
  if (group.creator?.name) num += 1
  if (group.members?.length) num += group.members.length
  return num
}

/**
 * 判断是否在指定的时间范围
 * @param opts.cur
 * @param opts.start
 * @param opts.end
 * @return {*}
 */
function IsInTimeFrame(opts = {}) {
  let timeFrame = {
    isStart: false,
    isEnd: false,
    isStartBefore: false,
    isStartAfter: false,
    isEndBefore: false,
    isEndAfter: false,
    isWithin: false
  }
  const { cur, start, end } = opts;

  if (!cur) timeFrame

  if (start) {
    timeFrame.isStart = cur.getTime() > start.getTime()
    timeFrame.isStartBefore = !timeFrame.isStart
    timeFrame.isStartAfter = timeFrame.isStart
  }

  if (end) {
    timeFrame.isEnd = cur.getTime() > end.getTime()
    timeFrame.isEndBefore = !timeFrame.isEnd
    timeFrame.isEndAfter = timeFrame.isEnd
  }

  timeFrame.isWithin = timeFrame.isStart && !timeFrame.isEnd

  return timeFrame
}

/**
 * 获取 UA
 * @return {string}
 */
function GetUserAgent() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return userAgent.toLowerCase();
}

/**
 * 判断是否 ios 设备
 * @return {boolean}
 */
function IsIOS() {
  return /iPad|iPhone|iPod/i.test(GetUserAgent()) && !window.MSStream;
}

/**
 * 判断是否是 android 设备
 * @return {boolean}
 */
function IsAndroid() {
  return /android/i.test(GetUserAgent());
}

/**
 * 判断是否是 微信浏览器
 * @return {boolean}
 */
function IsWXWeb() {
  // return /MicroMessenger/i.test(GetUserAgent());
  return String(GetUserAgent().match(/MicroMessenger/i)) === 'micromessenger';
}

// 活动倒计时
let remainingTimer = null
// 获取活动剩余时间
const GetRemainingTime = ({ cur, start, end, done } = { cur: new Date() }) => {
  let dis_end = end.getTime() - cur.getTime()
  let dis_start = start.getTime() - cur.getTime()

  console.log('%c活动开始', 'color: orange; font-size: 24px;', start.toLocaleString())
  console.log('%c活动结束', 'color: skyblue; font-size: 24px;', end.toLocaleString())
  console.log('%c服务器时间', 'color: hotpink; font-size: 24px;', cur.toLocaleString())

  remainingTimer && clearInterval(remainingTimer)
  remainingTimer = setInterval(() => {
    if (dis_start > 1000) {
      dis_start -= 1000
      done?.(dis_start, 'before')

      if (dis_start < 1000) {
        dis_start = 0
        dis_end = end.getTime() - start.getTime()
      }
    } else if (dis_end < 1000) {
      done?.(-2, 'after')
      clearInterval(remainingTimer)
      remainingTimer = null
    } else {
      dis_end -= 1000
      done?.(dis_end, 'within')
    }
  }, 1000)
}

/**
 * 防抖
 * @param {Function} fn
 * @param {Number} wait
 * @return {Function}
 */
function Debounce(fn, wait = 200) {
  var timer = null;
  return function (...rest) {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(fn.bind(this, ...rest), wait);
  };
}

/**
 * px 转化为 rem
 * @param {Number} val 像素
 * @param {Number} remUnit remUnit: 75 代表 1rem = 75px; 所以当你一个100px值时，它会自动转成 (100px/75)rem
 * @return {String}
 */
function Px2rem(val, remUnit = 192, hasUnit = true) {
  val = parseFloat(val)
  return hasUnit ? (val / remUnit) + 'rem' : val / remUnit
}

/**
 * 判断是否是移动端设备
 */
function IsMobile() {
  let ua = navigator.userAgent;
  let isSafari = ua.indexOf("Safari") != -1 && ua.indexOf("Version") != -1; //通过浏览器，机型和touch事件判断
  let isIphone = ua.indexOf("iPhone") != -1 && ua.indexOf("Version") != -1;
  let isIPad = isSafari && !isIphone && "ontouchend" in document;
  console.log(isIPad);
  var is_mobile = navigator.userAgent.toLowerCase().match(/(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null; //进行userAgent匹配
  return is_mobile || isIPad
}

/**
 * 如果是移动端设备，则跳转到移动端页面
 */
function ToMobile() {
  if (ENV.ENV == 'dev') return
  if (IsMobile()) {
    window.location.href = ENV.ORIGIN + '/m/index.html';
  }
}

/**
 * 判断两个对象是否相等 
 */
function IsEqual(val, other) {
  return isEqual(val, other)
}

/**
 * 复制文本 
 * @param {Object|String} obj 触发器元素对象或id、class
 * @param {String} text 要复制的文本
 */
function CopyText(obj, text) {
  const vm = new Vue()
  const locale = vm.$locale
  const message = vm.$message
  // 复制cdk
  const clipboard = new ClipboardJS(obj, {
    text: () => text
  });
  clipboard.on("success", (e) => {
    message({
      type: "success",
      message: locale.toast.copied,
    });
    console.log(e);
    //打印动作信息（copy或者cut）
    console.info("Action:", e.action);
    //打印复制的文本
    console.info("Text:", e.text);
    //打印trigger
    console.info("Trigger:", e.trigger);
  });
  clipboard.on("error", (e) => {
    message({
      type: "error",
      message: locale.toast.failedToCopy,
    });
    console.log(e);
    console.info("Action:", e.action);
    console.info("Trigger:", e.trigger);
  });
}

/**
 * 获取url参数
 * @param {*} name 参数名
 * @param {*} url
 * @returns
 */
function GetParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[]]/g, '$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\\+/g, ' '));
}

/**
 * 生成二维码
 * @param {*} content 二维码内容
 * @returns {Promise}
 */
function GenerateQrcode(content) {
  return new Promise((res, rej) => {
    if (!content) {
      rej()
    } else {
      QRCode.toDataURL(content, {
        // type: "image/webp", // 默认是：image/png，可能的值：image/png，image/jpeg, image/webp.
        // quality: 0, // 如果请求的类型是image/jpeg或image/webp，一个介于0和1之间的数字表示图像质量。
        // version: undefined, // 二维码的版本
        // errorCorrectionLevel: 'H', // 默认是'M'
        // toSJISFunc: null, // 内部使用的帮助函数，用于将汉字转换为其移位JIS值。如果你需要对汉字模式的支持，请提供这个函数。
        margin: 1,
        // width: 100,
        // scale: 2,
        // color: {
        //   dark: "#C72222ff", // 默认黑色：#000000ff
        //   light: "#B99909", // 默认白色：#ffffffff
        // },
      })
        .then(url => res(url))
        .catch(err => rej(err))
    }
  })
}

export {
  GetObjFormKey,
  ALink,
  SavePicture,
  CalcGroupNumber,
  IsInTimeFrame,
  GetUserAgent,
  IsIOS,
  IsAndroid,
  IsWXWeb,
  GetRemainingTime,
  Debounce,
  Px2rem,
  IsMobile,
  ToMobile,
  IsEqual,
  CopyText,
  GetParameterByName,
  GenerateQrcode
}