const authKey = '_token'
import { encode, decode } from 'js-base64';

// 操作本地缓存数据
function setToken(val) {
  localStorage.setItem(authKey, val)
}
function getToken() {
  return localStorage.getItem(authKey)
}
function removeToken() {
  localStorage.removeItem(authKey)
}
function isExpired(token) {
  if (!token) return true
  const str = token.split('.')?.[1]
  if (!str) return true
  const data = JSON.parse(decode(str))
  return data.exp * 1000 < new Date().getTime()
}
function isLogin() {
  return !isExpired(getToken())
}

export {
  setToken,
  getToken,
  removeToken,
  isLogin
}