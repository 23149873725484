import ENV from 'scripts/constants/env'

const shareInfo = {
  url: encodeURIComponent(window.location.href),
  hash: encodeURIComponent("Warpath,tanktower "),
  title: "2023 Rise of Kingdoms Civilization Clash Event",
  keywords: 'Rise Of Kingdoms,Conquer The World,lilithgames,website,rok game, rok games, rise of kingdom, conquer with greece, rise to greatness, greece, greek, civilization, farlight games, strategy game, best strategy game, best civilization game, RTS, real time strategy',
  description: 'Conquer with Greece, Rise to Greatness.',
  image: ENV.ORIGIN + '/images/share/default.jpg'
}
// 分享
const PLATFORM = {
  facebook: () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareInfo.url}`)
  },
  twitter: () => {
    window.open(`https://twitter.com/intent/tweet?text=${shareInfo.title}&hashtags=${shareInfo.hash}${shareInfo.url}`)
  },
  mail: () => {
    window.location.href = `mailto:?subject=${shareInfo.title}&body=${shareInfo.description}：%0D%0A${shareInfo.url}。`
  },
  message: () => {
    window.location.href = `sms:?subject=${shareInfo.title}&body=${shareInfo.description}：%0D%0A${shareInfo.url}`
  }
}

export default function (platform) {
  PLATFORM[platform]?.()
}