var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Dialog",
        _vm._g(
          _vm._b(
            {
              attrs: { title: _vm.locale.loginNow, visible: _vm.show },
              on: { close: _vm.onClose },
            },
            "Dialog",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c(
            "form",
            {
              staticClass: "form ff--common",
              attrs: { autocomplete: "off" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "form-item uid" }, [
                _c("label", { attrs: { for: "uid" } }, [
                  _vm._v(_vm._s(_vm.locale.gameUID) + ":"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.uid,
                      expression: "formData.uid",
                    },
                  ],
                  attrs: {
                    placeholder: _vm.locale.enterGameUid,
                    id: "uid",
                    name: "uid",
                    type: "text",
                  },
                  domProps: { value: _vm.formData.uid },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "uid", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-item code" }, [
                _c("label", { attrs: { for: "code" } }, [
                  _vm._v(_vm._s(_vm.locale.verificationCode) + ":"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.code,
                      expression: "formData.code",
                      modifiers: { trim: true },
                    },
                  ],
                  attrs: {
                    placeholder: _vm.locale.enterCode,
                    id: "code",
                    name: "code",
                    type: "text",
                  },
                  domProps: { value: _vm.formData.code },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "code", $event.target.value.trim())
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "gtag",
                        rawName: "v-gtag:getCode",
                        arg: "getCode",
                      },
                    ],
                    staticClass: "getcode",
                    attrs: { "data-type": "button", id: "getcode" },
                    on: { click: _vm.getCode },
                  },
                  [
                    _vm.loading.code
                      ? _c("i", {
                          staticClass: "loading el-icon-refresh-right",
                        })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.countdown
                            ? _vm.countdown + "s"
                            : _vm.locale.getCode
                        )
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "form-item help" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "gtag",
                        rawName: "v-gtag:whereToGetUID",
                        arg: "whereToGetUID",
                      },
                    ],
                    attrs: { id: "login-help", "data-type": "link" },
                    on: {
                      click: function ($event) {
                        return _vm.help("open")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.locale.howToGet))]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "form-item agree",
                  class: { "agree--checked": _vm.formData.agree },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.agree,
                        expression: "formData.agree",
                      },
                    ],
                    attrs: { id: "agree", name: "agree", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.formData.agree)
                        ? _vm._i(_vm.formData.agree, null) > -1
                        : _vm.formData.agree,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.formData.agree,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.formData, "agree", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.formData,
                                "agree",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.formData, "agree", $$c)
                        }
                      },
                    },
                  }),
                  _c("label", {
                    directives: [
                      { name: "gtag", rawName: "v-gtag:agree", arg: "agree" },
                    ],
                    ref: "agree",
                    attrs: { for: "agree" },
                  }),
                ]
              ),
              _c(
                "button",
                {
                  directives: [
                    { name: "gtag", rawName: "v-gtag:login", arg: "login" },
                  ],
                  staticClass: "dialog-btn submit",
                  attrs: { "data-type": "button" },
                  on: { click: _vm.submit },
                },
                [
                  _c("span", [
                    _vm.loading.submit
                      ? _c("i", {
                          staticClass: "loading el-icon-refresh-right",
                        })
                      : _vm._e(),
                    _vm._v("\n          " + _vm._s(_vm.locale.loginNow)),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("HowGetUid", {
        attrs: { visible: _vm.showHowGetUid },
        on: {
          close: function ($event) {
            return _vm.help("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }