import { Message } from 'element-ui'

const createMessage = () => {
  let msgInstance = null
  const message = function (options) {
    // Message.closeAll()
    if (!msgInstance || msgInstance._isBeingDestroyed) {
      msgInstance = null
      msgInstance = Message.call(this, options)
      return msgInstance
    }
  };
  Object.keys(Message).forEach(k => message[k] = Message[k])
    ;['error', 'info', 'success', 'warning'].forEach(type => {
      message[type] = function (options) {
        console.log('options', options, msgInstance);
        // Message.closeAll()
        if (!msgInstance || msgInstance._isBeingDestroyed) {
          msgInstance = null
          msgInstance = Message[type].call(this, options)
          return msgInstance
        }
      };
    })
  return message
}

export {
  createMessage
}

export default createMessage()