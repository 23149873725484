<template>
  <el-dialog
    custom-class="dialog"
    :visible.sync="show"
    :width="width"
    :before-close="onBeforeClose"
    append-to-body
  >
    <div class="dialog-inner">
      <div class="dialog-content">
        <slot name="header">
          <div class="dialog-header ff--trajanpro-bold">
            <div class="dialog-close" @click="close"></div>
            <div class="dialog-title" :class="hasDecor ? 'has-decor' : ''">
              {{ title }}
            </div>
          </div>
        </slot>
        <div class="dialog-body ff--trajanpro-bold">
          <slot></slot>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "Dialog",
  props: {
    width: {
      type: String,
      default: "4.317rem" /*829px*/,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    title: String,
    hasDecor: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    visible: {
      handler(val) {
        console.log(val);
        this.show = val;
      },
      immediate: true,
    },
  },
  created() {
    console.log("visible", this.visible);
  },
  mounted() {},
  computed: {},
  methods: {
    // 弹窗关闭前
    onBeforeClose() {},
    // 取消
    onCancel() {},
    // 确定
    onConfirm() {},
    // 关闭
    close() {
      this.show = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-dialog {
    background-color: transparent;
    box-shadow: none;
    border-radius: none;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>

<style lang="less">
.dialog {
  @border-width: 6px;
  @border-radius: 24px;

  &-inner {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  &-content {
    position: relative;
    padding: @border-width;
    box-shadow: 15px 20px 8px 0px rgba(0, 0, 0, 0.41),
      inset 1px 1px 0px 0px #fff;
    border-radius: @border-radius;
    background: url(~images/dialog/bg2.png) no-repeat 0 0;
    background-size: 100% 100%;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: @border-width;
      right: @border-width;
      bottom: @border-width;
      left: @border-width;
      background: url(~images/dialog/bg.png) no-repeat 0 0;
      background-size: 100% 100%;
      border-radius: @border-radius - @border-width;
      z-index: -1;
    }
  }

  &-header {
    position: relative;
    padding: 24px 60px;
    background-color: #3d2516;
    overflow: hidden;
    border-radius: @border-radius - @border-width @border-radius - @border-width
      0 0;
  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 11px;
    background-color: #2e190f;
    border-radius: 6px;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      width: 28px;
      height: 28px;
      background: url(~images/dialog/close.png) no-repeat;
      background-size: auto;
      background-position: center;
      transition: 0.3s;
    }

    &:hover {
      &::before {
        transform: rotateZ(360deg);
      }
    }
  }

  &-title {
    position: relative;
    max-width: 100%;
    height: 42px;
    font-size: 36px;
    line-height: 42px;
    color: var(--primary-color);
    text-align: center;
    text-shadow: 1px 2px 0px rgba(57, 46, 32, 0.54),
      0px 0px 1px rgba(255, 233, 129, 0.69);

    &.has-decor {
      padding: 0 58px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 6px;
        width: 48px;
        height: 30px;
        background: no-repeat 0 0;
        background-size: 100% 100%;
      }

      &::before {
        left: 0;
        background-image: url(~images/dialog/title_decor.png);
      }

      &::after {
        right: 0;
        background-image: url(~images/dialog/title_decor_2.png);
      }
    }
  }
}
</style>
