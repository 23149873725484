import axios from "../axios";
export default {
  /**
   * 登录
   * @param {Object} params 
   * @param {String} params.uid 
   * @param {String} params.code 
   * @returns {Promise}
   */
  async login(params) {
    try {
      const res = await axios.post('/api/login', params)
      return res
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },

  /**
   * 获取验证码
   * @param {Object} params 请求参数
   * @param {String} params.uid 游戏uid
   * @param {String} params.lang 当前语言
   * @returns {Promise}
   */
  async getCode(params) {
    try {
      const res = await axios.get('/api/verifier_get', { params })
      return res
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },

  /**
   * 获取用户信息
   * @returns {Promise}
   */
  async getUserInfo() {
    try {
      const res = await axios.get('/api/user_info')
      return res
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },

  /**
   * 加入文明
   * @param {Object} params 请求参数
   * @param {String} params.guild 文明名称
   * @returns {Promise}
   */
  async joinCivi(params) {
    try {
      const res = await axios.post('/api/apply', params)
      return res
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },

  /**
   * 个人战力排行榜
   * @param {Object} params 请求参数
   * @param {String} params.guild 文明名称
   * @returns {Promise}
   */
  async getPersonalRankings(params) {
    try {
      const res = await axios.get('/api/rank', { params })
      return res
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },

  /**
   * 文明排行版
   * @returns {Promise}
   */
  async getCiviRankings() {
    try {
      const res = await axios.get('/api/guild_rank')
      return res
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },

  /**
   * 抽奖
   */
  async raffle() {
    try {
      const res = await axios.post('/api/raffle')
      return res
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },

  /**
   * 抽奖记录
   */
  async getRecords() {
    try {
      const res = await axios.get('/api/raffle_log')
      return res
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },

  /**
   * 用户分享
   */
  async share() {
    try {
      const res = await axios.post('/api/share')
      return res
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },

  /**
   * 刷新用户任务状态
   */
  async refreshTaskStatus() {
    try {
      const res = await axios.post('/api/raffle/refresh')
      return res
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  }
};