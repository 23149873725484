/**
 * 发送自定义上报时间
 * @param {*} eventName 事件名称
 * @param {*} eventParams 自定义参数
 */
let send = function (eventName, eventParams = {}) {
  gtag('event', eventName, {
    ...eventParams
  })
}

export default {
  /**
   * 点击导航栏
   */
  click_viewOfficialSite: function () {
    send('click_viewOfficialSite')
  },
  /**
   * 点击退出登录
   */
  click_logout: function () {
    send('click_logout')
  },
  /**
   * 点击导航栏
   */
  click_nav: function (part) {
    send('click_nav', {
      part
    })
  },
  /**
   * 点击下载按钮
   * @param {String} btn download-App Store | download-Google Play | download-PC | download-PC floating
   */
  click_download: function (btn) {
    send('click_download', {
      btn
    })
  },
  /**
   * 点击加入文明
   * @param {String} civilization 14个文明名称
   */
  click_join: function (civilization) {
    send('click_join', {
      civilization
    })
  },
  /**
   * 点击文明轮播图,左箭头
   */
  click_arrowLeft: function () {
    send('click_arrowLeft')
  },
  /**
   * 点击明轮播图,右箭头
   */
  click_arrowRight: function () {
    send('click_arrowRight')
  },
  /**
   * 点击获取验证码
   */
  click_getCode: function () {
    send('click_getCode')
  },
  /**
   * 点击去哪获取uid
   */
  click_whereToGetUID: function () {
    send('click_whereToGetUID')
  },
  /**
   * 点击登录按钮
   * @param {Boolean} success 登录成功
   */
  click_login: function (success) {
    send('click_login', {
      success
    })
  },
  /**
   * 点击同意隐私协议
   */
  click_agree: function () {
    send('click_agree')
  },
  /**
   * 点击加入文明弹窗里的确认按钮
   */
  click_joinConfirm: function () {
    send('click_joinConfirm')
  },
  /**
   * 点击加入文明弹窗里的取消按钮
   */
  click_joinCancel: function () {
    send('click_joinCancel')
  },
  /**
   * 点击查看规则按钮
   */
  click_viewRules: function () {
    send('click_viewRules')
  },
  /**
   * 点击文明细节按钮
   * @param {String} civilization 14个文明名称
   */
  click_details: function (civilization) {
    send('click_details', {
      civilization
    })
  },
  /**
   * 点击查看奖品按钮
   */
  click_viewRewards: function () {
    send('click_viewRewards')
  },
  /**
   * 点击复制礼包码按钮
   */
  click_copyCode: function () {
    send('click_copyCode')
  },
  /**
   * 点击抽奖按钮
   */
  click_scratch: function () {
    send('click_scratch')
  },
  /**
   * 点击刷新按钮
   */
  click_refresh: function () {
    send('click_refresh')
  },
  /**
   * 点击中奖记录按钮
   */
  click_prizeHistory: function () {
    send('click_prizeHistory')
  },
  /**
   * 点击分享按钮
   */
  click_share: function (platform) {
    send('click_share', {
      platform
    })
  },
  /**
   * 点击复制链接按钮
   */
  click_copyLink: function () {
    send('click_copyLink')
  },
}