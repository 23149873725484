<template>
  <div id="wrapper" class="container">
    <SideNav />
    <Header />
    <MainView />
    <Join />
    <KOL />
    <Rank v-if="isLogin && isJoinCivi" />
    <Data />
    <Game />
    <Follow />
    <Footer />
  </div>
</template>

<script>
// import SideNav from "components/side-nav/index.vue";
// import Header from "components/header/index.vue";
// import MainView from "components/main-view/index.vue";
// import Join from "components/join/index.vue";
// import KOL from "components/kol/index.vue";
// import Rank from "components/rank/index.vue";
// import Data from "components/data/index.vue";
// import Game from "components/game/index.vue";
// import Follow from "components/follow/index.vue";
// import Footer from "components/footer/index.vue";
//
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    SideNav: () =>
      import(
        /* webpackChunkName: 'side-nav' */ "components/side-nav/index.vue"
      ),
    Header: () =>
      import(/* webpackChunkName: 'header' */ "components/header/index.vue"),
    MainView: () =>
      import(
        /* webpackChunkName: 'main-view' */ "components/main-view/index.vue"
      ),
    Join: () =>
      import(/* webpackChunkName: 'join' */ "components/join/index.vue"),
    KOL: () => import(/* webpackChunkName: 'kol' */ "components/kol/index.vue"),
    Rank: () =>
      import(/* webpackChunkName: 'rank' */ "components/rank/index.vue"),
    Data: () =>
      import(/* webpackChunkName: 'data' */ "components/data/index.vue"),
    Game: () =>
      import(/* webpackChunkName: 'game' */ "components/game/index.vue"),
    Follow: () =>
      import(/* webpackChunkName: 'follow' */ "components/follow/index.vue"),
    Footer: () =>
      import(/* webpackChunkName: 'footer' */ "components/footer/index.vue"),
  },
  data() {
    return {};
  },
  created() {
    this.isLogin && this.getUserInfo();
  },
  computed: {
    ...mapGetters(["isLogin", "isJoinCivi"]),
  },
  methods: {
    ...mapActions(["getUserInfo"]),
  },
};
</script>

<style lang="less">
body {
  overflow: hidden auto;
}

:root {
  --header-height: 73px;
  --footer-hegiht: 192px;
  --primary-color: #ffdd97;
}

.container {
  position: relative;
  padding: var(--header-height) 0 0;
  width: 1920px;
  /* overflow: hidden auto; */
  font-size: 14px;
  /* background: #f0eee5 url(~images/bg.jpg) no-repeat center 0; */
  background-color: #f0eee5;
  background-repeat: no-repeat;
  background-position: 0 0, 0 1151px, 0 1151px + 1844px,
    0 1151px + 1844px + 1239px, 0 1151px + 1844px + 1239px + 2731px;
  background-size: 100% 1151px, 100% 1844px, 100% 1233px, 100% 2731px,
    100% 1315px;
  background-image: url(~images/bg/1.jpg), url(~images/bg/2.jpg),
    url(~images/bg/3.jpg), url(~images/bg/4.jpg), url(~images/bg/5.jpg);
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  span {
    display: block;
    background: no-repeat 0 0;
    background-size: 100% 100%;

    &:nth-child(1) {
      height: 1154px;
      background-image: url(~images/bg/1.jpg);
    }

    &:nth-child(2) {
      height: 1850px;
      background-image: url(~images/bg/2.jpg);
    }

    &:nth-child(3) {
      height: 1239px;
      background-image: url(~images/bg/3.jpg);
    }

    &:nth-child(4) {
      height: 2731px;
      background-image: url(~images/bg/4.jpg);
    }

    &:nth-child(5) {
      height: 1315px;
      background-image: url(~images/bg/5.jpg);
    }
  }
}

> .inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.header,
.kv,
.join,
.kol,
.rank,
.list,
.rewards,
.follow {
  width: 1920px;
}

.l-center {
  width: 60%;
  margin: 0 auto;
}

.title {
  text-align: center;
}

.modal {
  pointer-events: none;
}

button {
  border: 0;
  background-color: transparent;
}

.dialog-btn {
  // width: 500px;
  position: relative;
  height: 94px;
  padding: 0 40px;
  // background-color: #fff;
  background: url(~images/dialog/btn_bg_center.png) repeat-x 0 0;
  background-size: 42px 100%;
  background-clip: content-box;
  font-size: 30px;
  line-height: 86px;
  letter-spacing: -1px;
  color: #fff;
  text-shadow: 1px 1px 0px rgba(131, 113, 83, 0.67);
  cursor: pointer;
  .ff--trajanpro-bold;

  span {
    display: inline-block;
    padding: 0 10px;
    margin-bottom: 8px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 40px;
    height: 100%;
    background: no-repeat 0 0;
    background-size: 100% 100%;
  }

  &::before {
    left: 0;
    background: url(~images/dialog/btn_bg_left.png) repeat-x 40px 0;
    background-size: 40px 100%;
  }

  &::after {
    right: 0;
    background: url(~images/dialog/btn_bg_right.png) repeat-x 40px 0;
    background-size: 40px 100%;
  }
}

/*  */
.ff--common {
  font-family: "SOURCEHANSANSCN-MEDIUM", -apple-system, "Helvetica Neue", Arial,
    "PingFang SC", "Hiragino Sans GB", "Source Han Sans", "Microsoft YaHei",
    sans-serif;
}

/*  */
.ff--trajanpro-bold {
  font-family: "TRAJANPRO-BOLD";
}

/* table */
.table {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0 auto;
  width: 745px;
  height: 397px;
  background: url(~images/rank/sub_table_bg.png) no-repeat 0 0;
  background-size: 100% auto;

  .empty {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
  }

  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;

    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }

  .col {
    flex: 1 1 auto;
    text-align: center;
    font-size: 28px;
    line-height: 30px;
    color: var(--primary-color);
    // text-shadow: 1px 1px 0px rgba(57, 46, 32, 0.54);
  }

  .thead {
    flex-shrink: 0;
    height: 62px;
    background-color: transparent;

    .col {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      font-size: 22px;
      line-height: 1;
      font-weight: bold;
      color: #724634;
      .ff--trajanpro-bold;

      &:not(:last-child)::after {
        position: absolute;
        right: -5px;
        top: 0;
        content: "";
        width: 7px;
        height: 100%;
        background: url(~images/rank/sub_title_border.png) no-repeat 0 center;
        background-size: 100% auto;
      }
    }
  }

  .tbody {
    flex: 1;
    padding: 15px;
    overflow: hidden auto;

    > .row {
      width: 100%;
      height: 38px;
      background-color: #4f302081;
      border-radius: 23px;
    }

    .col {
      font-size: 16px;
    }
  }

  .tfoot {
    border-top: 1px solid #d6c09799;
    padding: 0 15px;
    height: 50px;
    .row {
      height: 100%;
    }
    .col {
      font-size: 16px;
    }
  }
}

/* 隐藏元素 */
.hidden {
  display: none;
}

/* 按钮类型 */
[data-type="button"] {
  cursor: pointer;
  transition: 0.3s ease-in;

  &:hover {
    transform: scale(1.05, 1.05);
  }

  &.is-disabled {
    cursor: not-allowed;
    filter: grayscale(100%);

    &:hover {
      transform: none;
    }
  }
}

/* 链接类型 */
[data-type="link"] {
  cursor: pointer;
  transition: 0.3s ease-in;
  text-decoration: underline;

  &:hover {
    transform: scale(1.05, 1.05);
  }
}

/* 文字溢出省略 */
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* 文字多行溢出省略 */
.text-ellipsis-more {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 
  自定义滚动条样式 
  ::-webkit-scrollbar 滚动条整体部分，可以设置宽度啥的
  ::-webkit-scrollbar-button 滚动条两端的按钮
  ::-webkit-scrollbar-track  外层轨道
  ::-webkit-scrollbar-track-piece  内层滚动槽
  ::-webkit-scrollbar-thumb 滚动的滑块
  ::-webkit-scrollbar-corner 边角
  ::-webkit-resizer 定义右下角拖动块的样式
*/
.scroll-bar {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
}

/*  */
input::placeholder {
  color: #aaa6;
}
</style>
