export default {
  title: 'RISE OF KINGDOMS',
  sideNav: {
    // 活动规则
    rules: 'Event Rules',
    // pc版下载
    downloadPC: 'Download PC Version',
    // 扫码下载游戏
    scanToDownload: 'Scan to download the game',
  },
  header: {
    // 加入文明
    join: 'Join a Civilization',
    // KOL推荐
    kol: "Who's on Your Side",
    // 文明排行
    rank: 'Civilization Rankings',
    // 文明数据
    data: 'Civilization Stats',
    // 赢取好礼
    rewards: 'Great Rewards',
    // 关注我们
    follow: 'Follow Us'
  },
  mainView: {
    // 进入官网
    officialSite: 'Visit Official Website',
    // 登录
    login: 'Log in',
    // 退出登录
    logout: 'Login Out',
  },
  join: {
    // 未上榜
    NotOnTheList: 'Unranked',
    // 排名
    ranking: 'Ranking',
    // 玩家UID
    playerUID: 'Player UID',
    // 贡献战力值
    contributed: 'Power Contributed',
    // 去加入
    join: 'Join',
    // 已加入
    joined: 'Joined',
    // 当前文明总战力
    totalPower: 'Current Civilization Total Power',
    // 当前文明总人数
    totalPlayers: 'Current Civilization Total Players',
    // 贡献表格
    table: {
      title: 'CONTRIBUTION',
      // 排名
      ranking: 'Ranking',
      // 玩家
      playerName: 'Player Name',
      // 玩家UID
      playerUID: 'Player UID',
      // 贡献战力值
      powerContributed: 'Power Increased',
      // 已加入
      joined: 'Joined',
    }
  },
  kol: {
    // 支持你心仪的KOL，选择你们的文明，打造最强帝国吧~
    propaganda: [
      'Show your support for your favorite influencer',
      'when choosing your civilization',
      'and build the strongest empire!'
    ]
  },
  rank: {
    // 文明排行
    civiRank: 'Civilization Leaderboard',
    // 数据最后更新时间
    lastUpdated: 'Update Daily',
    // 获奖名单均已活动结束当天的最新榜单为准
    tip: 'The list of winners will be based on the latest rankings when the event ends',
    // 玩家名称
    playerName: 'Player Name',
    // 文明排行表格
    civiTable: {
      // 排名
      ranking: 'Rank',
      // 文明名称
      civiName: 'Civilization Name',
      // 总人数
      totalPlayers: 'Total Players',
      // 战力值
      power: 'Power',
      // 操作
      operate: '',
      // 详情
      details: 'Details'
    },
    // 玩家排行表格
    playerTable: {
      // 排名
      ranking: 'Rank',
      // 玩家名
      name: 'Player Name',
      // UID
      uid: 'UID',
      // 战力
      power: 'Power'
    },
    // 查看奖励
    viewRewards: 'How can I get rewards?',
    // 查看更多文明排行
    viewFull: 'View Full Leaderboard',
    // 收起
    collapse: 'Collapse'
  },
  data: {

  },
  rewards: {
    // 全民参与奖
    participationReward: 'Participation Reward',
    // 在ROK游戏端输入下方礼包码，即可获得银钥匙道具*20个
    enterCode: 'Enter the promo code below in-game to get 20 Silver Keys [New Player Only]',
    // 复制
    copy: 'Copy',
    // 复制成功
    copied: 'Copied!',
    // 任务刮刮乐
    questScratchcards: 'Quest Scratchcards',
    // 完成相应任务，有机会刮出亚马逊礼品卡
    winAmazonCards: 'Complete quests for extra chances to win Amazon gift cards',
    // 刮奖次数不足，完成任务可获得刮奖次数
    completeGetMore: 'You have not earned any scratchcards; complete quests for more',
    // 每日最多可获得5次刮奖次数
    scratch5cards: 'You can scratch up to 5 cards per day.',
    // 请先加入文明后再来刮奖哦~
    joinCiviFirst: 'Please join a civilization first',
    // 刮刮乐
    draw: {
      // 很遗憾，没有中奖
      unfortunately: 'Unfortunately, you didn’t win a prize',
      // 开始刮奖
      scratch: 'Scratch',
      // 剩余次数
      chancesRemaining: 'Chances Remaining',
      // 快刮我呀~
      scratchMe: 'Scratch me!',
      // 中奖记录
      prizeHistory: 'Prize History',
    },
    // 任务
    task: {
      // 每日登录游戏
      loginGame: 'Log In Daily',
      // 每日分享该页面
      sharePage: 'Share this page every day',
      // 成功选择文明
      chooseCivi: 'Choose a civilization',
      // 个人达到Level 8
      reachLv8: 'Reach City Hall level 8',
      // 个人解锁Pathfinding
      researchPathfinding: 'Research Pathfinding',
      // 个人达到1万
      reach10000: 'Reach 10k power',
      // 去加入
      toJoin: 'Join',
      // 去分享
      toShare: 'Share',
      // 获取方式
      method: 'Method',
      // 刮奖次数
      chancesEarned: 'Chances Earned',
      // 完成情况
      progress: 'Progress',
      // 已完成
      completed: 'Completed',
      // 未完成
      notCompleted: 'Not Completed',
    },
  },
  dialog: {
    login: {
      // 立即登录
      loginNow: 'Log in Now',
      // 游戏uid
      gameUID: 'Game UID',
      // 请输入您游戏内角色的UID
      enterGameUid: 'Please enter your in-game UID.',
      // 验证码
      verificationCode: 'Verification Code',
      // 获取验证码
      getCode: 'Get Verification Code',
      // 如何获取UID和验证码
      howToGet: 'How to get my UID and verification code?',
      // 我已阅读并同意遵守相关服务条款和隐私协议
      agree: 'I have read and accepted the ${Terms of Service} & ${Privacy Policy}.',
      // 验证码已发送
      codeSent: 'Verification code sent',
      // 发送失败
      sendFailed: 'Failed to send',
      // 请输入UID
      enterUID: 'Please enter your UID',
      // 请输入验证码
      enterCode: 'Please check your in-game mailbox',
      // 请输入正确的验证码
      enterValidUID: 'Please enter a valid UID',
      // 请输入正确的验证码
      enterValidCode: 'Please enter a valid verification code',
      // 验证码已过期，请重新获取
      codeExpired: 'Verification code expired; please request another',
      // 请先阅读并勾选服务条款和隐私协议
      readPolicy: 'Please read the Terms of Service and Privacy Policy',

    },
    howToGetUIDAndCode: {
      // 游戏uid
      gameUID: 'Game UID',
      // 验证码
      verificationCode: 'Verification Code',
      // 如何获取UID和验证码
      howToGet: 'How to Get UID and Verification Code',
      // 请在游戏对应账号的邮箱内查看
      checkYourInGameMailbox: 'Please check your in-game mailbox',
      // 进入游戏个人中心，复制名称下面的UID码即可
      goToProfileCopyUID: 'Go to your in-game Profile and copy the UID under your name',
      // 在页面点击获取验证码后，进入游戏邮箱内查收即可
      afterSelecting: 'After selecting “Get Verification Code”, check your in-game mailbox to get your code',
    },
    join: {
      // 加入文明
      joinCivi: 'Join Civilization',
      // 是否确定加入【xxxx】文明
      isJoin: 'Join ${0}?',
      // 成功加入文明后不可更换，请谨慎选择
      cannotChange: 'You cannot change your civilization after joining one.',
      // 确定
      confirm: 'Confirm',
      // 再想想
      cancel: 'Cancel',
      // 加入文明成功
      success: 'Successfully Joined Civilization'
    },
    joinedSuccess: {
      // 加入文明成功
      success: 'You have joined a civilization',
      // 恭喜您，快分享给好友一起加入文明赢取奖励吧！
      toShare: 'Congratulations! Invite your friends to join your civilization and win more rewards!'
    },
    award: {
      // 恭喜获得
      youGot: 'You Got',
      // 确定
      confirm: 'Confirm',
      // 谢谢参与
      noPrize: 'No Prize',
      // 很遗憾，没有中奖
      sorry: 'Unfortunately, you didn’t win a prize',
    },
    records: {
      // 中奖记录
      prizeHistory: 'Prize History',
      // 当前暂未获得任何奖励
      notWonAnyPrizes: 'You have not won any prizes yet',
      // 当前领奖UID
      uid: 'UID',
      // 我们将通过游戏内邮箱与你联系
      viaInGameMail: 'WE WILL GET IN TOUCH WITH YOU VIA IN-GAME MAIL WITHIN 7 DAYS AFTER THIS EVENT END',
      // 奖励名称
      prizeName: 'Prize Name',
      // 获奖时间
      time: 'Time',
    },
    share: {
      // 分享页面
      sharePage: 'Share Page',
      // 请选择您的分享方式
      chooseShareMethod: 'Please choose a platform',
      // 请在手机上打开后分享
      onlyAvailableOnMobile: 'Only available on mobile'
    },
    reminder: {
      // 温馨提示
      reminder: 'Reminder',
      // 是否确定跳转打开游戏
      isOpenROK: 'Open RoK?',
      // 取消
      cancel: 'Cancel',
      // 确定
      confirm: 'Confirm',
    },
    rewards: {
      personalRewards: 'Individual Rewards',
      // 支持获得战力值前三的文明中，个人战力最强玩家可对应获得下方奖励
      inEachOf: `In each of the top three civilizations, the player with the highest individual power increase will win a prize based on their civilization's final ranking`,
      // 文明池大奖
      civilizationPool: 'Civilization Pool Grand Prizes',
      // 将在加入前三个最强文明的所有玩家中，分别随机抽取1名玩家获得以下奖励
      theTopThree: '1 player will be randomly chosen from each of the top three civilizations to win one of the following rewards',
      // 战力第一文明
      p1st: '1st Place Civilization',
      // 战力第二文明
      p2nd: '2nd Place Civilization',
      // 战力第三文明
      p3rd: '3rd Place Civilization',
      // 获奖者
      winner: 'Winner',
      // 敬请期待
      comingSoon: 'Coming Soon',
      // 虚位以待
      undecided: 'Undecided',
    },
    rules: {
      // 活动规则
      eventRules: 'Event Rules'
    }
  },
  toast: {
    copied: 'Copied',
    failedToCopy: 'Failed to copy',
    confirm: 'Confirm',
    cancel: 'Cancel',
    confirmed: 'Confirmed',
    noData: 'No Data',
    refresh5H: 'Refresh Every {5} Hours',
    networkError: 'Network Error, Please Try Again Later',
    operationFrequent: 'Operation Frequent, Please Try Again Later',
    Login: 'Login',
    Logout: 'Logout',
    copy: 'Copy',
    noLotteryTimes: 'Insufficient Lottery Times, Completing Tasks Can Get Lottery Times',
    confirmUIDFormat: 'Please Confirm UID Format',
    enterUID: 'Please Enter UID Number',
    enterCode: 'Please Enter Verification Code',
    agree: 'Please Read and Agree to the Relevant Service Terms and Privacy Policy',
    noRecord: 'No Record',
    switchScreenOrientation: 'Switch Screen Orientation',
    dataBeingCompiled: 'Data is Being Compiled',
    lookForward: 'Please Look Forward To',
    loginSuccess: 'Login Success',
    loginFail: 'Login Fail',
    eventsEnded: 'The event has ended, thank you for your participation.',
  },
}