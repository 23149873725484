import Vue from 'vue'
import { PopupManager } from 'element-ui/src/utils/popup';
import DlgLogin from 'components/dialog/login.vue'

const loginConstructor = Vue.extend(DlgLogin)
let instance = null

function Open() {
  if (!instance) {
    instance = new loginConstructor();
    instance.visible = true;
    instance.$on('close', () => {
      login.$emit('close')
    })
    instance.$on('success', res => {
      login.$emit('success', res)
    })
    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.$el.style.zIndex = PopupManager.nextZIndex();
  } else {
    instance.visible = true;
    instance.$el.style.zIndex = PopupManager.nextZIndex();
  }
}

function Close() {
  if (instance) {
    instance.visible = false
  }
}

const login = new Vue()
login.$on('open', Open)
login.$on('close', Close)

export default login