var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "wrapper" } },
    [
      _c("SideNav"),
      _c("Header"),
      _c("MainView"),
      _c("Join"),
      _c("KOL"),
      _vm.isLogin && _vm.isJoinCivi ? _c("Rank") : _vm._e(),
      _c("Data"),
      _c("Game"),
      _c("Follow"),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }